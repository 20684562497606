// author:马云阳；description:众惠-公募-转入
<template>
    <section class="PublicFundProcess">
        <!-- <NavRightSide/> -->
      <div class="publicfundprocess_cont">
        <a @click="topage" class="title_">
          <span class="iconfont">&#xe665;</span>&emsp;{{funddata.fundName}}
        </a>
        <div class="side_cont">
          <ProgressBar :sideData="side" />
        </div>
        <div class="solids_"></div>
        <div class="card_">
          <div class="titles_">买入基金</div>
          <div class="card_new">
            <div class="card_l">
              <div class="right_ti">
                <div class="nema_ti">
                  <span class="num">{{funddata.fundCode}}</span>
                  <span class="name_">{{funddata.fundName}}</span>
                </div>
                <div class="tags">
                  <div class="tag_">
                    {{sys_fund_riskleveldata[funddata.ofundRisklevel]||'--'}}
                  </div>
                  <div class="tag_">
                    {{sys_fund_typedata[funddata.ofundType]||'--'
                    }}
                  </div>
                  <div class="tag_">{{sys_fund_share_typedata[funddata.shareType]||'--'}}</div>
                </div>
              </div>
              <a @click="topage" class="right_btn"><span>更换产品</span><span class="iconfont icon-trade-exchange" style="color:#DEB87B;margin-right:6px;"></span></a>
            </div>
          </div>
        </div>
        <div class="play_type">
        <div class="ti_">支付方式</div>
        <div class="bank_card">
            <div @click="bankswith(2)" :class="bankindex==2?'bank_listac':''" class="bank_list">
            <img v-if="bankindex!=2" src="@/static/img/trade_flow/trade-bankpay-unselect.png" alt="" class="l_imgs">
            <img v-if="bankindex==2" src="@/static/img/trade_flow/trade-bankpay-select.png" alt="" class="l_imgs">
            <div :class="bankindex==2?'c_nameac':''" class="c_name">快捷支付</div>
            <div class="r_share">单日限额 <span class="color_">{{banklist.singeldayLimit}}元</span>，单笔限额 <span class="color_">{{banklist.singelLimit}}元</span></div>
            <div v-if="bankindex==2" class="right_true"><span>✓</span></div>
          </div>
          <div v-if="bankindex==2" class="bankcard_">
            <div :class="cardindex==i?'backs_ac':''" @click="getcard(item,i)" v-for="(item,i) in play" :key="i"
              class="banks_">
              <div class="top_ban">
                <img :src="item.logoUrl" alt="">
                <span class="bank_name">{{item.bankName||''}}</span>
              </div>
              <div class="cardnum_">{{item.bankAccount||''}}</div>
              <div v-if="cardindex==i" class="right_true"><span>✓</span></div>
            </div>
            <a @click="topagebank" class="addbank_">
              <div style="margin-top:4px;transform: rotate(45deg);margin-right:6px"><span
                  style="font-size:18px;color:#bdc0cb;" class="iconfont">&#xe6ab;</span></div>
              <span class="add_">添加银行</span>
            </a>
          </div>
          <!-- v-if="newfund==0" -->
          <div   @click="bankswith(1)" :class="bankindex==1?'bank_listac':''" class="bank_list">
            <img v-if="bankindex!=1" src="@/static/img/trade_flow/trade-huijinbao-unselect.png" alt="" class="l_imgs">
            <img v-if="bankindex==1" src="@/static/img/trade_flow/trade-huijinbao-select.png" alt="" class="l_imgs">
            <div :class="bankindex==1?'c_nameac':''" class="c_name">惠金宝</div>
            <div class="r_share">当前可用余额 <span class="color_">{{superAccts.enableShare||"0"}}元</span></div>
            <div v-if="bankindex==1" class="right_true"><span>✓</span></div>
          </div>
          <div v-if="bankindex==1" class="bankcard_">
            <div :class="xjbindex==k?'backs_ac':''" @click="getcardxjb(items,k)" v-for="(items,k) in xjbList" :key="k"
              class="banks_">
              <div class="top_ban">
                <img :src="items.logoUrl" alt="">
                <span class="bank_name">{{items.bankName||''}}</span>
              </div>
              <div class="cardnum_">{{items.bankAccount||''}}</div>
              <div v-if="xjbindex==k" class="right_true"><span>✓</span></div>
            </div>
            <a @click="topagebank" class="addbank_">
              <div style="margin-top:4px;transform: rotate(45deg);margin-right:6px"><span
                  style="font-size:18px;color:#bdc0cb;" class="iconfont">&#xe6ab;</span></div>
              <span class="add_">添加银行</span>
            </a>
          </div>
        <!-- v-if="newfund==0" -->
          <div   @click="bankswith(3)" :class="bankindex==3?'bank_listac':''" class="bank_list">
            <img v-if="bankindex!=3" style="width:24px;height:19px;"
              src="@/static/img/trade_flow/trade-offline-unselect.png" alt="" class="l_imgs">
            <img v-if="bankindex==3" style="width:24px;height:19px;"
              src="@/static/img/trade_flow/trade-offline-select.png" alt="" class="l_imgs">
            <div :class="bankindex==3?'c_nameac':''" class="c_name">线下转账</div>
            <div class="r_share"></div>
            <div v-if="bankindex==3" class="right_true"><span>✓</span></div>
          </div>
        </div>
        <div class="input_share">
          <div class="ti_">买入金额</div>
          <div class="input_box">
            <div class="input_">
              <input style="width:90%;height:100%;" @blur="blurs($event)" @keydown="clerInput" v-model="numprice"
                class="input_s" type="number" :placeholder="`请输入购买金额(最低金额${numFormat(subStartAmt,false)}元起)`">
              <!-- <a @click.stop="long" style="height:46px;line-height:46px;width:10%;" class="long">全部</a> -->
            </div>
            <span class="fen_s">&emsp;元</span>
            <a @click="showrule" class="rules_btn">交易规则</a>
          </div>
          <div v-if="showti" class="red_ti">
            <!-- {{titxt}} -->
          </div>
          <div class="rule_txt">
            <!-- <div class="rule_ti"></div> -->
            <!-- 预计赎回费{{numprice*(matchObj.discountRate/100)||0.00}}元， -->
            <div class="rule_">交易须知:&emsp;购买费率 <span :class="matchObj.discountRate?'throughs':''"
                >{{rate||0.00}}</span>，<span v-if="matchObj.discountRate">折后{{discountRate||0.00}},</span>预估购买费用：{{costPrice||'--'}}，购买确认日：T&emsp;+&emsp;
                {{matchObj.buyConfirmDate||''}}</div>
          </div>
          <div class="rule_file">
            <div class="file_ti">
              <span style="margin-right:6px;color:#CE9B63" class="iconfont">&#xe670;</span>
              <div class="file_txt_ti">为了您的资金安全，交易遵守 <a class="file_btn">同卡进出</a> </div>
            </div>
            <div class="files_">
              <input  v-model="checkbool" style="width:10px;height:10px;margin-top: 3px;"
                type="checkbox">
              <div class="files_btns">
                阅读并同意
                <a @click="outline&&outline.length>0?onoutline():treatyerr()" class="tobtn_">《产品概要》、</a>
                <a @click="contract&&contract.length>0?oncontract():treatyerr()" class="tobtn_">《基金合同》、</a>
                <a  @click="instructions&&instructions.length>0?oninstructions():treatyerr()" class="tobtn_">《招募说明书》、</a>
                <a  @click="obj.protUrl?toPdf(obj.protUrl):tofundnotice(obj.id)" v-for="(obj,j) in propage" :key="j" class="tobtn_">{{obj.protNameDesc}}、</a>
                
                <a  @click="obj.protUrl?toPdf(obj.protUrl):tofundnotice(obj.id)" v-for="(obj,j) in annAndProtlist" :key="j" class="tobtn_">{{'《'+obj.infotitle+'》'||obj.protNameDesc}}、</a>
                等内容
              </div>
            </div>
            <div class="foot_btns">
              <div class="left_num">
                <div class="num_ti">待支付</div>
                <div class="nums_">{{Number(numprice)||0}}<span class="fen_"></span>
                </div>
              </div>
              <a @click="topeges()" class="right_btn">下一步</a>
            </div>
          </div>
        </div>
        </div>
      </div>
    <div class="fund-rule">
      <fund-detail-rule ref="fundRule" :fundCode="fundCodes"/>
    </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import FundDetailRule from "@/views/fund-market/components/FundDetailRule.vue";
import { getDicts } from "@/api/dict.js";
import { protPage } from "@/api/other/index.js";
import { annAndProt } from "@/api/commom.js";
import { findXjbAccoList } from "@/api/getMoney.js";

import ProgressBar from "@/components/ProgressBar";
import { baseInfo } from "@/api/mysetcast";
import {
  superAcct,
  findTradeBankAcct,
  queryUserIncomeDetail,
  rateMagMatch,
  buyProd,
  getOldBankList
} from "@/api/addfund";
export default {
  name: "BuyingFund",
  components: { ProgressBar, FundDetailRule },
  beforeCreate() {
    let token = this.$store.getters.token;
    let _userInfo = localStorage.getItem("customerInfo");
    if (!token || !_userInfo) {
      this.$message.warning("请登录!");
      this.$router.push({ name: "Login" });
      return;
    }
    let userInfo = JSON.parse(_userInfo);
    if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
      this.$message.warning("请先开户!");
      this.$router.push({ name: "OpenAccount" });
      return;
    }
    if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
      this.$message.warning("请补充资料!");
      this.$router.push({ name: "PersonalDataSupply" });
      return;
    }
    if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
      this.$message.warning("请设置交易密码!");
      this.$router.push({ name: "OpenAccount" });
      return;
    }
    if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
      this.$message.warning("请先完成风险测评!");
      this.$router.push({ name: "RiskAssessment" });
      return;
    }
  },
  data() {
    return {
      fundCodes: "",
      side: [
        {
          title: "填写买入信息",
          type: 2
        },
        {
          title: "输入交易密码",
          type: 0
        },
        {
          title: "交易结果",
          type: 0
        }
      ],
      play: [],
      xjbindex: 0,
      xjbList: [],
      palybank: [], //未隐藏银行卡
      userinfos: {}, //当前用户信息
      bankindex: 2, //默认是索引第一个
      checkbool: false,
      numprice: null,
      banklist: {
        singelLimit: 0,
        singeldayLimit: 0
      }, //默认银行卡数据为第一个
      showti: false,
      cardindex: 0,
      superAccts: {}, //惠金宝账目
      xjbye: "0.00", //现金宝可用余额
      matchObj: {}, //每日
      sys_fund_riskleveldata: {}, //产品风险等级
      sys_fund_typedata: {}, //基金类型
      sys_fund_share_typedata: {}, //产品份额
      /** 基金协议 */
      propage: [], //平台协议
      newfund: 0, //查询是新发还是申购
      annAndProtlist: [], //基金协议及公告
      contract: [], //产品合同
      outline: [], //产品概要
      instructions: [], //说明书
      funddata: {}, //当前页面数据
      oldBanklist: [], //旧用户银行卡
      subStartAmt: 1,
      hasOldBank: false //是否有未绑定的旧卡
    };
  },
  computed: {
    rate() {
      if (this.matchObj.rate) {
        if (this.matchObj.rate >= 100) {
          return this.matchObj.rate + "元";
        } else {
          return this.matchObj.rate + "%";
        }
      } else {
        return "--";
      }
    },
    discountRate() {
      if (this.matchObj.discountRate) {
        if (this.matchObj.rate >= 100) {
          return this.matchObj.rate + "元";
        } else {
          return (this.matchObj.discountRate * this.matchObj.rate / 100).toFixed(2) + "%";
        }
      } else {
        return "--";
      }
    },
    costPrice() {
      if (this.matchObj.rate) {
        if (this.matchObj.discountRate) {
          if (this.matchObj.rate >= 100) {
            return (
              (this.matchObj.discountRate / 100 * this.matchObj.rate).toFixed(
                2
              ) + "元"
            );
          } else {
            return (
              (
                this.matchObj.discountRate /
                100 *
                this.matchObj.rate *
                this.numprice /
                100
              ).toFixed(2) + "元"
            );
          }
        } else {
          if (this.matchObj.rate >= 100) {
            return this.matchObj.rate + "元";
          } else {
            return (
              (this.matchObj.rate * this.numprice / 100).toFixed(2) + "元"
            );
          }
        }
      } else {
        return "--";
      }
    }
  },
  methods: {
    onoutline() {
      if (this.outline && this.outline[0] && this.outline[0].protUrl) {
        this.toPdf(this.outline[0].protUrl);
      } else {
        this.tofundnotice(this.outline[0].id);
      }
    },
    oncontract() {
      if (this.contract && this.contract[0] && this.contract[0].protUrl) {
        this.toPdf(this.contract[0].protUrl);
      } else {
        this.tofundnotice(this.contract[0].id);
      }
    },
    oninstructions() {
      if (
        this.instructions &&
        this.instructions[0] &&
        this.instructions[0].protUrl
      ) {
        this.toPdf(this.instructions[0].protUrl);
      } else {
        this.tofundnotice(this.instructions[0].id);
      }
    },
    bankswith(o) {
      this.bankindex = o;
      if (o == 1) {
        this.getxjbList();
      }
      if (o == 2) {
        this.getlits();
      }
    },

    getDictionaries(val, option) {
      this.getDicts(val).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            // this[option][item.dictValue] = item.dictLabel
            this.$set(this[option], item.dictValue, item.dictLabel);
          });
        }
      });
    },
    annAndProtdata() {
      annAndProt({
        fundCode: this.funddata.fundCode,
        protLik: "4",
        protType: "2",
        status: "1"
      }).then(res => {
        let annList = res.data.annList;
        let protList = res.data.protList;
        const len = annList.length;
        const len2 = protList.length;
        let arr1 = [];
        let arr2 = [];
        for (let i = 0; i < len; i++) {
          if (annList[i].infotitle.includes("合同")) {
            this.contract.push(annList[i]);
          } else if (annList[i].infotitle.includes("说明书")) {
            this.instructions.push(annList[i]);
          } else if (annList[i].infotitle.includes("概要")) {
            this.outline.push(annList[i]);
          } else {
            arr1.push(annList[i]);
          }
        }
        for (let i = 0; i < len2; i++) {
          if (protList[i].protNameDesc.includes("合同")) {
            this.contract.push(protList[i]);
          } else if (protList[i].protNameDesc.includes("说明书")) {
            this.instructions.push(protList[i]);
          } else if (protList[i].protNameDesc.includes("概要")) {
            this.outline.push(protList[i]);
          } else {
            arr2.push(protList[i]);
          }
        }
        this.annAndProtlist = [...arr1, ...arr2];
      });
    },
    /** 获取产品协议 */
    getProtocol() {
      protPage({
        pageNum: 1,
        pageSize: 10,
        protLink: "4",
        protType: "1"
      }).then(result => {
        this.propage = result.data.data;
      });
    },
    blurs() {
      let teg = /^[0-9]+.?[0-9]*$/;
      this.numprice = this.numprice.match(/\d+(\.\d{0,2})?/)
        ? this.numprice.match(/\d+(\.\d{0,2})?/)[0]
        : 0;
      if (!this.numprice && !teg.test(this.numprice)) {
        this.showti = true;
        return;
      } else {
        this.showti = false;
        rateMagMatch({
          fundCode: this.funddata.fundCode,
          rateType: "1",
          amount: Number(this.numprice)
        }).then(r => {
          this.matchObj = r.data && r.data.length ? r.data[0] : {};
        });
      }
    },
    clerInput(e) {
      let key = e.key;
      if (key === "e" || key === "E" || key === "-" || key === "+") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    getcard(obj, i) {
      this.cardindex = i;
      this.banklist = this.palybank[i]; //切换银行卡
    },
    getcardxjb(obj, i) {
      this.xjbindex = i;
      console.log("--", this.xjbindex);
      this.superAccts = this.xjbList[i]||{}; //切换银行卡
    },
    getxjbList() {
      findXjbAccoList({}).then(res => {
        this.superAccts = res.data && res.data[0]||{};
        this.xjbList = res.data||[];
        this.xjbindex = 0;
      });
    },
    getfund(a) {
      baseInfo({
        fundCode: a
      }).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.funddata = res.data;
          this.subStartAmt = res.data.subStartAmt || 1;
          if (this.newfund == 0) {
            this.getsuperAcct();
          }
          this.annAndProtdata();
          this.getProtocol();
          this.getDictionaries("sys_fund_risklevel", "sys_fund_riskleveldata");
          this.getDictionaries(
            "sys_fund_share_type",
            "sys_fund_share_typedata"
          );
          this.getDictionaries("sys_fund_type", "sys_fund_typedata");
        }
      });
    },
    getsuperAcct() {
      // superAcct().then(res => {
      //   if (res.code != 200) {
      //     this.$message.error(res.msg);
      //   } else {
      //     this.superAccts = res.data || {};
      //   }
      // });
      // queryUserIncomeDetail({
      //   userId: this.userinfos.userId,
      //   xjbFlag: "1"
      // }).then(r => {
      //   this.xjbye = r.data ? r.data.totalMoney : "0.00";
      //   // console.log("r", r);
      // });
      this.getxjbList();
      this.getlits();
    },
    getlits() {
      findTradeBankAcct().then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          let datas = res.data;

          // 未绑卡时判断是否有旧卡
          if (this.oldBanklist.length > 0 && datas.length === 0) {
            this.hasOldBank = true;
          }

          this.palybank = res.data;
          this.banklist = (res.data && res.data[0]) || {};
          let a = JSON.stringify(datas);
          let b = JSON.parse(a);
          this.play = b.map(item => {
            let str = item.bankAccount.slice(0, 4);
            let str2 = item.bankAccount.slice(-4);
            item.bankAccount = str + " **** **** " + str2;
            return item;
          });
        }
      });
    },
    topage() {
      this.$router.go(-1);
    },
    showrule() {
      this.$refs.fundRule.show = true;
    },
    topagebank() {
      let route = this.hasOldBank ? "OldBankCard" : "BankCardAdd";
      this.$router.push({ name: route });
    },
    topeges() {
      if (this.bankindex == 1) {
        let teg = /^[0-9]+.?[0-9]*$/;
        if (!this.numprice && !teg.test(this.numprice)) {
          this.$message.error("请正确输入购买金额！");
          return;
        } else if (Number(this.numprice) < Number(this.subStartAmt)) {
          this.$message.error(
            `金额不能低于${this.numFormat(this.subStartAmt, false)}元`
          );
          return;
        } else if (this.numprice > Number(this.superAccts.enableShare)) {
          this.$message.error("输入金额超过可用金额！请重新输入");
        } else if (!this.checkbool) {
          this.$message.error("请阅读并同意基金购买相关协议！");
        } else if (JSON.stringify(this.superAccts) == "{}") {
          this.$message.error("您未开通惠金宝，请开通后再试！");
        } else if (JSON.stringify(this.matchObj) == "{}") {
          this.$message.error("请稍后再试！");
        } else {
          let params = {
            fund: this.funddata,
            num: Number(this.numprice),
            banklist: this.superAccts,
            newfund: this.newfund,
            type: 1
          };
          this.$router.push({ name: "BuyingFundPassword", params: { params } });
        }
      } else if (this.bankindex == 2) {
        let teg = /^[0-9]+.?[0-9]*$/;
        if (!this.numprice && !teg.test(this.numprice)) {
          this.$message.error("请正确输入购买金额！");
          return;
        } else if (Number(this.numprice) < Number(this.subStartAmt)) {
          this.$message.error(
            `金额不能低于${this.numFormat(this.subStartAmt, false)}元`
          );
          return;
        } else if (this.numprice > this.banklist.singelLimit) {
          this.$message.error("输入金额超过可用金额！请重新输入");
        } else if (!this.checkbool) {
          this.$message.error("请阅读并同意基金购买相关协议！");
        } else if (JSON.stringify(this.banklist) == "{}") {
          this.$message.error("请正确添加银行卡");
        } else if (JSON.stringify(this.matchObj) == "{}") {
          this.$message.error("请稍后再试！");
        } else {
          let params = {
            fund: this.funddata,
            num: Number(this.numprice),
            banklist: this.banklist,
            newfund: this.newfund,
            type: 2
          };
          this.$router.push({ name: "BuyingFundPassword", params: { params } });
        }
      } else if (this.bankindex == 3) {
        let teg = /^[0-9]+.?[0-9]*$/;
        if (!this.numprice && !teg.test(this.numprice)) {
          this.$message.error("请正确输入购买金额！");
          return;
        } else if (Number(this.numprice) < Number(this.subStartAmt)) {
          this.$message.error(
            `金额不能低于${this.numFormat(this.subStartAmt, false)}元`
          );
          return;
        } else if (!this.checkbool) {
          this.$message.error("请阅读并同意基金购买相关协议！");
        } else if (JSON.stringify(this.matchObj) == "{}") {
          this.$message.error("请稍后再试！");
        } else {
          let a = {
            fund: this.funddata,
            num: Number(this.numprice),
            newfund: this.newfund,
            type: 3
          };
          let params = JSON.stringify(a);
          this.$router.push({ name: "BuyingFundOffline", query: { params } });
        }
      }
    }
  },
  created() {
    getOldBankList().then(result => {
      this.oldBanklist = result.data || [];
    });

    this.userinfos =
      JSON.parse(window.localStorage.getItem("customerInfo")) || {};

    let a = JSON.parse(sessionStorage.getItem("GgaddfundCode"));
    if (!this.$route.params.fundCode && a != "undefined") {
      this.getfund(a.fundCode);
      this.fundCodes = a.fundCode;
      this.newfund = a.newfund || 0;
      if (this.newfund == 1) {
        this.getlits();
        this.bankindex = 2;
      } else {
        this.bankindex = 2;
      }
    } else {
      window.sessionStorage.setItem(
        "GgaddfundCode",
        JSON.stringify(this.$route.params)
      );
      this.getfund(this.$route.params.fundCode);
      this.fundCodes = this.$route.params.fundCode;
      this.newfund = this.$route.params.newfund || 0;
      if (this.newfund == 1) {
        this.getlits();
        this.bankindex = 2;
      } else {
        this.bankindex = 2;
      }
    }
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}
.fund-rule {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}
.PublicFundProcess {
  padding: 0px 0px 50px 0px;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 40px;
      margin-top: 30px;
      .titles_ {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #1f1f1f;
      }

      .card_new {
        border-radius: 12px;
        box-shadow: 0px 5px 10px 0px #edf1f7;
        margin-top: 24px;
        height: 80px;
        padding: 24px 24px 0 24px;
        width: 802px;
        .card_l {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          .right_ti {
            .nema_ti {
              .num {
                color: #bdc0cb;
                font-size: 18px;
                font-weight: bold;
                margin-right: 10px;
              }
              .name_ {
                color: #1f1f1f;
                font-size: 18px;
              }
            }
            .tags {
              margin-top: 16px;
              display: flex;
              align-items: center;
              .tag_ {
                padding: 6px 20px;
                border-radius: 14px;
                font-size: 12px;
                color: #8691a8;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                border: 1px solid #e0e2e8;
                margin-right: 14px;
                &:first-child {
                  border: none;
                  background: #fff5ea;
                  color: #ce9b63;
                  padding: 7px 20px;
                }
              }
            }
          }
          .right_btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: 30px;
            span {
              font-size: 12px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #000000;
            }
            img {
              width: 12px;
              height: 12px;
              margin-right: 12px;
            }
          }
        }
      }
    }
    .play_type {
      padding: 0px 40px;
      margin-top: 40px;
      .ti_ {
        font-size: 16px;
        font-family: ABeeZee, ABeeZee-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #1f1f1f;
        margin-bottom: 14px;
      }
      .bank_card {
        .bank_list {
          width: 468px;
          height: 46px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          position: relative;
          margin-bottom: 24px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          .l_imgs {
            width: 26px;
            height: 26px;
            margin-right: 19px;
          }
          .c_name {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            text-align: LEFT;
            color: #25293d;
            width: 127px;
          }
          .c_nameac {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #25293d;
          }
          .r_share {
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #25293d;
            .color_ {
              color: #ce9b63;
            }
          }
          .right_true {
            position: absolute;
            right: -16px;
            top: -16px;
            width: 0px;
            height: 0px;
            border: 16px solid #f7d9b7;
            border-color: transparent transparent #eabf96 transparent;
            transform: rotate(45deg);
            // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
            span {
              color: #fff;
              position: absolute;
              transform: rotate(-35deg);
              top: -1px;
              right: -5px;
            }
          }
        }
        .bank_listac {
          border: 1px solid;
          border-image: linear-gradient(95deg, #f7d9b7 0%, #eabf96 100%) 1 1;
        }
        .bankcard_ {
          margin-bottom: 30px;
          padding: 30px 24px 6px 24px;
          background: #f6f5f3;
          display: flex;
          border-radius: 2px;
          // align-items: center;
          // justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
          .backs_ac {
            width: 208px !important;
            border: 1px solid #ce9b63;
            height: 54px !important;
            border-radius: 4px;
          }
          .banks_ {
            padding: 22px 20px;
            width: 210px;
            height: 56px;
            background: #fff;
            margin-right: 24px;
            margin-bottom: 24px;
            position: relative;
            border-radius: 4px;
            &:nth-child(3n + 3) {
              margin-right: 0px;
            }
            .right_true {
              position: absolute;
              right: -16.5px;
              top: -16.5px;
              width: 0px;
              height: 0px;
              border: 16px solid #f7d9b7;
              border-color: transparent transparent #eabf96 transparent;
              transform: rotate(45deg);
              // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
              span {
                color: #fff;
                position: absolute;
                transform: rotate(-35deg);
                top: -1px;
                right: -5px;
              }
            }
            .top_ban {
              display: flex;
              align-items: center;
              img {
                width: 22px;
                height: 22px;
                margin-right: 7px;
              }
              .bank_name {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }
            }
            .cardnum_ {
              margin-top: 20px;
              font-size: 18px;
              font-family: Bahnschrift, Bahnschrift-Light;
              font-weight: 300;
              color: #1f1f1f;
            }
          }
          .addbank_ {
            width: 249px;
            height: 98px;
            border: 1px dashed #bdc0cb;
            border-radius: 4px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            .add_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #bdc0cb;
            }
          }
        }
      }
      .input_share {
        .ti_ {
          font-size: 16px;
          font-family: ABeeZee, ABeeZee-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #1f1f1f;
          margin-bottom: 14px;
        }
        .input_box {
          display: flex;
          align-items: center;
          .input_ {
            display: flex;
            align-items: center;
            padding-left: 24px;
            justify-content: space-between;
            border: 1px solid #e0e2e8;
            border-radius: 2px;
            width: 494px;
            height: 46px;
            .long {
              font-size: 14px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ce9b63;
            }
          }
          .fen_s {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: LEFT;
            color: #1f1f1f;
            margin-right: 14px;
          }
          .rules_btn {
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #ce9b63;
          }
        }
        .red_ti {
          margin-top: 19px;
          color: red;
          font-size: 12px;
        }
        .rule_txt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 64px;
          padding: 0px 14px;
          background: rgba(255, 245, 234, 0.5);
          border-radius: 2px;
          margin-top: 30px;
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;
          .rule_ {
            margin-top: 4px;
          }
          .throughs{
            text-decoration:line-through;
          }
        }
        .rule_file {
          margin-top: 30px;
          .file_ti {
            display: flex;
            align-items: center;
            .file_img_ti {
              width: 22px;
              height: 22px;
              margin-right: 3px;
            }
            .file_txt_ti {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #1f1f1f;
              .file_btn {
                color: #ce9b63;
              }
            }
          }
          .files_ {
            margin-top: 17px;
            display: flex;

            .files_btns {
              width: 500px;
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #1f1f1f;
              .tobtn_ {
                color: #ce9b63;
              }
            }
          }
          .foot_btns {
            display: flex;
            margin-top: 22px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 70px;
            .left_num {
              .num_ti {
                font-size: 12px;
                font-family: Poppins, Poppins-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }
              .nums_ {
                font-size: 26px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
                font-weight: 700;
                color: #e45247;
                .fen_ {
                  font-size: 12px;
                  font-family: Poppins, Poppins-Regular;
                  font-weight: 400;
                  color: #1f1f1f;
                  margin-left: 10px;
                }
              }
            }
            .right_btn {
              background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
              border-radius: 2px;
              height: 48px;
              line-height: 48px;
              text-align: center;
              width: 200px;
              color: #b88141;
            }
          }
        }
      }
    }
  }
}
</style>
